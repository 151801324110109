<template>
	<!-- 售后订单 -->
	<div class="order-sale">
		<page-sale :is-shop="1"></page-sale>
	</div>
</template>

<script>
	import pageSale from '@/components/layout/goods/page-sale.vue'
	export default{
		components:{
			pageSale
		},
		data(){
			return{
				
			}
		}
	}
</script>

<style lang="scss">
</style>
